body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus-visible{
  outline: none !important;
}

a{
  text-decoration: none;
}

/* estados del archivo */
.pendiente p{
  margin: 0;
  border: solid 2px #D62B2B;
  display: inline;
  border-radius: 5px;
  color: #D62B2B;
  padding: 5px 10px;
  cursor: default;
}

.atendido p{
  margin: 0;
  border: solid 2px #39C93E;
  display: inline;
  border-radius: 5px;
  color: #39C93E;
  padding: 5px 10px;
  cursor: default;
}
/* estados del archivo */


/* login */
.login{
  background-color: #ffffff;
  width: 400px;
  padding: 50px;
  border-radius: 20px;
}
.login-form{
  margin-bottom: 20px;
}
.login-form:nth-child(3n) img{
  cursor: pointer;
}

.login-form{
  border: solid 1px #707070;
  background-color: #F9F9F9;
  color: #000000;
  border-radius: 50px;
  padding-left: 20px;
  display: flex;
  gap: 10px;
}

.login-form input{
  border-radius: 0 50px 50px 0 !important;
  border: none !important;
  width: calc(100% - 0px) !important;
  margin: 0 !important;
  height: 30px;
  padding: 10px 0;
}

.login-form img{
  width: 20px;
}

.boton-login{
  background-color: #BD1F60;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-radius: 50px;
  border: solid 2px #BD1F60;
  font-size: 20px;
  padding: 0px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.boton-login:hover{
  background-color: #ffffff;
  color: #BD1F60;
  transition: all ease-in-out 0.3s;
}
/* login */

/* sidenav */
.sidenav-custom{
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav-custom > div{
  width: 50%;
}
.sidenav-title > p {
  color: #BD1F60;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}
.sidenav-user{
  display: flex;
  margin-left: 50px;
}
.sidenav-user div{
  margin-left: 10px;
}
.sidenav-user p{
  margin: 0;
}

.sidenav-opcion{
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #BBBBBB;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.sidenav-opcion svg{
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
.sidenav-opcion:hover{
  color: #BD1F60;
  transition: all 0.3s ease-in-out;
}
.sidenav-opcion:hover  path{
  fill: #BD1F60;
  transition: all 0.3s ease-in-out;
}
/* sidenav */

/* botones */
button{
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: 'Poppins', Courier, monospace;
  cursor: pointer;
}

.boton-morado{
  background-color: #A541AD;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
}

.boton-rojo{
  background-color: #D62B2B;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
}
.boton-rojo:focus{
  background-color: #D62B2B;
}

.boton-verde{
  background-color: #39C93E;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
}
.boton-verde:focus{
  background-color: #39C93E;
}

.boton-azul{
  background-color: #2A79AE;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
}

.agregar-imagen{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.agregar-imagen + label{
  background-color: #bd1f6175;
  color: #BD1F60;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: all linear 0.3s;
}
.agregar-imagen + label:hover{
  background-color: #bd1f6190;
  transition: all linear 0.3s;
}


/* botones */

/* titulos y textos */
.titulo-1{
  font-size: 22px;
  color: #000000;
  font-weight: bolder;
}

.texto-1{
  color: #747678;
  font-weight: bolder;
  font-size: 20px;
}
/* titulos */

/* fondos y bordes */
.contenedor-1{
  background-color: #ffffff;
  padding: 20px 50px;
  margin: 30px;
  height: calc(100vh - 153px - 30px);
  max-height: calc(100vh - 153px - 30px);
  overflow: auto;
  border-radius: 25px;
}
.contenedor-2{
  width: calc(100% - 50px);
  border: #BCBCBC 1px solid;
  border-radius: 15px;
  padding: 10px 25px;
  min-height: 350px;
  background: #FEFEFE;
}
/* fondos y bordes */


/* tabla */
.tabla-header{
  color: #707070;
  font-weight: bolder;
  position: relative;
  left: -15px;
}

.tabla-body{
  color: #000000;
  font-weight: bolder;
}

/* tabala nueva */
th, td{
  font-family: 'Poppins' !important;
  border: none !important;
  color: #000000 !important;
  font-weight: bolder !important;
  font-size: 14px !important;
}

.MuiPaper-root{
  box-shadow: none !important;
}
.MuiTable-root thead th{
  font-family: 'Poppins' !important;
  color: #707070 !important;
  font-weight: bolder !important;
  position: relative;
  font-size: 17px !important;
  /* left: -15px; */
}
.MuiTable-root thead{
  border: none;
  /* left: -15px; */
}

.MuiToolbar-root p .MuiSelect-select{
  font-family: 'Poppins' !important;
  color: #525252 !important;
  font-weight: bolder !important;
}
.MuiToolbar-root p{
  font-family: 'Poppins' !important;
  color: #525252 !important;
  font-weight: bolder !important;
}
/* tabla */


.sombra{
  -webkit-box-shadow: 0px 24px 30px -12px rgba(0,0,0,0.41);
  -moz-box-shadow: 0px 24px 30px -12px rgba(0,0,0,0.41);
  box-shadow: 0px 24px 30px -12px rgba(0,0,0,0.41);
}


/* formulario */
.formulario-inputs > div {
  margin: 5px 0px;
}

/* valid */
.MuiFormControl-root:has(input[type = "email"]:valid, input[type = "text"]:valid, input[type = "password"]:valid) label {
  color: #39C93E !important;
}
.MuiInputBase-root:has(input[type = "email"]:valid, input[type = "text"]:valid, input[type = "password"]:valid)::before{
  border-bottom: 2px solid #39C93E !important;
}
/* valid */


/* invalid */
.MuiFormControl-root:has(input:invalid) label {
  color: #D62B2B;
}
.MuiInputBase-root:has(input:invalid)::before{
  border-bottom: 2px solid #D62B2B;
}
/* email */
.MuiFormControl-root:has(input[type = "email"]:not(:placeholder-shown):invalid) label {
  color: #D62B2B !important;
}
.MuiInputBase-root:has(input[type = "email"]:not(:placeholder-shown):invalid)::before{
  border-bottom: 2px solid #D62B2B !important;
}
/* email */
/* invalid */


/* empty */
.MuiFormControl-root:has(input:required) label {
  color: #707070;
}
.MuiInputBase-root:has(input:required)::before{
  border-bottom: 2px solid #707070;
}
/* empty */


/* focus */
.MuiFormControl-root:has(input:valid:focus) label {
  color: #2A79AE !important;
}
.MuiInputBase-root:has(input:valid:focus)::before{
  border-bottom: 2px solid #2A79AE !important;
}
/* focus */

/* select */
.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root{
  background: transparent !important;
  color: #707070;
}
.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before{
  border-bottom: 2px solid #707070 !important;

}
/* select */
/* formulario */


/* imagenes */
.galeria-imagenes{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: stretch;
}
.galeria-imagenes img{
  width: 100px;
  border-radius: 10px;
  cursor: zoom-in;
  transition: all linear 0.3s;
}
.galeria-imagenes img:hover{
  width: 100px;
  border-radius: 10px;
  filter: brightness(.7);
  transition: all linear 0.3s;
}

.fondo-imagen{
  position: absolute;
  z-index: 9998;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: #00000054;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}
.fondo-imagen img{
  height: 80%;
}

.fondo-imagen-off{
  display: none;
}
/* imagenes */
